
export const Beneficios = ({ imgBeneficio1 }) => {
    return (

        <div className='row mt-3'>
            <div className='col-12 col-lg-6'>
                <img src={imgBeneficio1} alt="beneficio 5" className='img-fluid rounded p-4'></img>
            </div>
            <div className='col-12 col-lg-6 align-self-center'>
                <ul className="list-group list-group-flush p-3">
                    <li >Documentación mínima para estudio de crédito.</li>
                    <li >Aprobación rápida.</li>
                    <li >No requiere desplazamientos, nuestros asesores realizan el proceso de
                        toma de firmas del cliente en su lugar de trabajo si es preciso.</li>
                    <li >La mejor atención personalizada de nuestros Ejecutivos Comerciales.</li>
                    <li >Con el respaldo de una gran compañía de carga nacional.</li>
                </ul>

                <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                    <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                </div>
            </div>
        </div>


    )
}
