
export const PrivacyPolicies = () => {
    return (
        <>
            <div className="container policy" style={{ paddingTop: "76px" }}>
                <div className="row">
                    <div className="col-12 ">
                        <h3 class="text-center my-4">POLÍTICA DE PROTECCIÓN DE DATOS PERSONALES</h3>
                        <p> <label class="fw-bolder">FICARGO S.A.S.</label>, comprometida con la protección de datos personales y en
                            cumplimiento de la Ley 1581 de 2012, Decreto 1377 del 2017 y Decreto 1727 del 2009,
                            así, como las demás normas que puedan modificar, derogar o sustituir esta Política,
                            dispone de mecanismos para la protección de datos personales aplicables a todas
                            sus bases de datos y/o archivos que contengan información de empleados, asociados,
                            clientes, proveedores y grupos de interés que sean objeto de tratamiento por
                            parte de la compañía.</p>
                        <p>
                            Actuando en calidad de responsable del tratamiento de datos personales, para el adecuado desarrollo de sus actividades comerciales, así como para el fortalecimiento de sus relaciones con terceros <label class="fw-bolder">FICARGO S.A.S.</label>, recolecta, almacena, usa, circula y suprime datos personales correspondientes a personas naturales o jurídicas con quienes tiene o ha tenido relación, tales como empleados, asociados, proveedores, clientes y grupos de interés.
                        </p>
                        <p>
                            Igualmente, será responsable de la atención de peticiones, consultas, quejas y reclamos, el Titular de la información podrá ejercer sus derechos a conocer, actualizar, rectificar, suprimir el dato y revocar el permiso; por lo tanto, es necesario solicitar autorización previa, expresa e informada a los Titulares de los datos personales sobre los que requiera realizar el tratamiento de los mismos.
                        </p>
                        
                    </div>
                </div>
                <div className="row" >
                    
                    <div className="col-12">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                        <h3 class="text-center my-4">POLÍTICA DE SEGURIDAD DE LA INFORMACIÓN</h3>
                        <p><label class="fw-bolder">FICARGO S.A.S.</label> se ha comprometido con la implementación de la política de seguridad de Ia información respecto al tratamiento general de privacidad, seguridad digital y continuidad de los servicios enfocados a las TIC's, lineamientos frente al uso, manejo de la información de empleados, terceros, aprendices, proveedores y clientes, así, como el cumplimiento de la Resolución 000512 de 2019 y demás normas que con tal fin se establezcan en la Legislación Colombiana Vigente.</p>
                        <p>
                            La protección de la información busca la disminución del impacto generado sobre los activos de <label class="fw-bolder">FICARGO S.A.S.</label>, con base en Ia mejora continua, los riesgos identificados de manera sistemática para mantener un nivel de exposición que permita responder por Ia integridad, confidencialidad y la disponibilidad de esta, acorde con la naturaleza del negocio, las siguientes premisas y sus demás políticas definidas. </p>
                        <ul>
                            <li>	Cumplir con los principios de seguridad de la información.
                            </li>
                            <li>	Cumplir con los principios de la gestión administrativa.
                            </li>
                            <li>	Mantener la confianza de empleados, terceros, aprendices, proveedores y clientes.
                            </li>
                            <li>	Apoyar la innovación tecnológica.
                            </li>
                            <li>	Proteger los activos tecnológicos.
                            </li>
                            <li>	Establecer las políticas, procedimientos e instructivos en materia de seguridad de la información.
                            </li>
                            <li>	Minimizar el riesgo en las funciones más importantes de la compañía.
                            </li>
                            <li>	Fortalecer la cultura de seguridad de la información en empleados, terceros, aprendices, proveedores y clientes.
                            </li>
                            <li>	Garantizar Ia continuidad del negocio frente a incidentes.

                            </li>
                        </ul>
                        <p><label class="fw-bolder">FICARGO S.A.S.</label> protegerá la información generada, procesada, transmitida o resguardada por los procesos de negocio, su infraestructura tecnológica, redes de datos, sistemas, recursos de red y activos del riesgo que se genera de los accesos otorgados a terceros o como resultado de un servicio interno en outsourcing para minimizar impactos financieros, operativos o legales debido a un uso incorrecto de esta.</p>
                        
                    </div>
                </div>

            </div>
        </>
    )
}
