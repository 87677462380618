
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom'
import Logo from './images/Logo FICARGO para Banner.png';
import ConstanstUI from './utilities/ConstanstUI';

export const NavBar = () => {
    const {LINK_LIBRE_INVERSION,LINK_PARTICULAR,LINK_PRODUCTIVO,LINK_POLIZAS}=ConstanstUI
    const handleActive = ({ isActive }) => {

        return "nav-item nav-link " + (isActive ? 'active' : '');
    }

    return (
        <>
            <Navbar collapseOnSelect className='fixed-top' expand="lg" variant="light" style={{ borderBottom: "1px solid #d8e7f1", backgroundColor: "white" }}>
                <Container>
                    <Link
                        className="navbar-brand"
                        to="/"
                    >

                        <img src={Logo} className="img-fluid" alt="logo" style={{ maxHeight: "50px" }} />
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">


                        </Nav>
                        <Nav>
                            <NavLink

                                className={handleActive}

                                to="/"
                            >
                                Inicio
                            </NavLink>
                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Productos"
                               
                            >
                                <NavDropdown.Item href="/productivos">Productivos</NavDropdown.Item>
                                <NavDropdown.Item href="/particular">
                                    Particular
                                </NavDropdown.Item>
                                <NavDropdown.Item href="/libreinversion">Libre Inversión</NavDropdown.Item>
                               
                                <NavDropdown.Item href="/polizas">
                                Pólizas 
                                </NavDropdown.Item>
                            </NavDropdown>


                            <NavDropdown
                                id="nav-dropdown-dark-example"
                                title="Solicitar Crédito "
                               
                            >
                                <NavDropdown.Item target="_blank" href={LINK_PRODUCTIVO}>
                                    Productivos
                                    </NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href={LINK_PARTICULAR}>
                                    Particular
                                </NavDropdown.Item>
                                <NavDropdown.Item target="_blank" href={LINK_LIBRE_INVERSION}>
                                    Libre Inversión
                                    </NavDropdown.Item>
                               
                                <NavDropdown.Item target="_blank" href={LINK_POLIZAS}>
                                Pólizas 
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link

                                className={handleActive}
                                target="_blank"
                                href="https://sadminweb.sadmin.net/PayValida.aspx?id9MC/7TNSnbSiinPZFW9Q=="
                            >
                               Pague aquí
                            </Nav.Link>
                            <Nav.Link

                                className={handleActive}
                                target="_blank"
                                href="https://sadminfactory.sadmin.net/login?c2FkbWluZmljYXJnb185MDE0MDA2MDlfZmFjdG9yeQ=="
                            >
                                Acceder
                            </Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )
}
