import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import Img1 from '../images/1 BANNER FICARGO Productivos.jpg'
import Img2 from '../images/2 BANNER FICARGO Particular.jpg'
import Img3 from '../images/3 BANNER FICARGO Libre Inversión.jpg'
import Img4 from '../images/4 BANNER FICARGO Polizas.jpg'
import ImgLogo from '../images/Logo FICARGO para Banner.png'
import ConstanstUI from '../utilities/ConstanstUI'

export const Banner = () => {

    const [index, setIndex] = useState(0);
    const {LINK_LIBRE_INVERSION,LINK_PARTICULAR,LINK_PRODUCTIVO,LINK_POLIZAS}=ConstanstUI
    
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Carousel style={{ paddingTop: "76px" }} activeIndex={index} onSelect={handleSelect} variant="dark">
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Img1}
                    alt="Productivos"
                />
                <Carousel.Caption className='carousel-productivo'>
                    <h3><img src={ImgLogo} alt="logobanner" className='img-banner-title'></img> Productivos</h3>
                    <p className='d-none d-lg-block'>Te ayudamos a cumplir el sueño de ser propietario de tu tractocamión o vehículo de carga. </p>
                    <div className='row mt-4 justify-content-center'>
                        <div className='col-5'>
                            <a className='btn btn-outline-primary  d-none d-lg-block' href='/productivos'>Conocer más</a>
                        </div>
                        <div className='col-5'>
                            <a className='btn btn-primary  d-none d-lg-block' 
                            href={LINK_PRODUCTIVO}
                            target="_blank" rel="noreferrer">Solicitar Crédito</a>
                        </div>
                    </div>


                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Img2}
                    alt="Particular"
                />

                <Carousel.Caption className='carousel-particular'>
                    <h3><img src={ImgLogo} alt="logobanner" className='img-banner-title'></img> Particular</h3>
                    <p className='d-none d-lg-block'>Elige el vehículo que mejor se adapte a tus sueños y a tu estilo de vida.  Nosotros te ayudaremos a comprarlo.</p>
                    <div className='row mt-4 justify-content-center'>
                        <div className='col-5'>
                            <a className='btn btn-outline-primary  d-none d-lg-block' href='/particular'>Conocer más</a>
                        </div>
                        <div className='col-5'>
                            <a className='btn btn-primary  d-none d-lg-block' 
                           href={LINK_PARTICULAR}
                           target="_blank" rel="noreferrer">Solicitar Crédito</a>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Img3}
                    alt="Libre Inversion"
                />

                <Carousel.Caption className='carousel-libre'>
                    <h3><img src={ImgLogo} alt="logobanner" className='img-banner-title'></img> Libre Inversión</h3>
                    <p className='d-none d-lg-block'>
                    Créditos sobre garantía real para lo que desees
                    </p>
                    <div className='row mt-4 justify-content-center'>
                        <div className='col-5'>
                            <a className='btn btn-outline-primary  d-none d-lg-block' href='/libreinversion'>Conocer más</a>
                        </div>
                        <div className='col-5'>
                            <a className='btn btn-primary  d-none d-lg-block' 
                            href={LINK_LIBRE_INVERSION} target="_blank" rel="noreferrer">Solicitar Crédito</a>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={Img4}
                    alt="Polizas"
                />

                <Carousel.Caption className='carousel-particular'>
                    <h3><img src={ImgLogo} alt="logobanner" className='img-banner-title'></img> Polizas</h3>
                    <p className='d-none d-lg-block'>
                    Financiamos el valor de tu póliza total anual y la renovación durante tu crédito. 
                    </p>
                    <div className='row mt-4 justify-content-center'>
                        <div className='col-5'>
                            <a className='btn btn-outline-primary  d-none d-lg-block' href='/polizas'>Conocer mas</a>
                        </div>
                        <div className='col-5'>
                            <a className='btn btn-primary  d-none d-lg-block' 
                            href={LINK_POLIZAS}
                            target="_blank" rel="noreferrer">Solicitar Credito</a>
                        </div>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}
