import React from 'react'
import Banner from '../images/6 BANNER FICARGO Particular B.jpg'
import Beneficio9 from '../images/foto Beneficio 9.jpg'
import Beneficio10 from '../images/foto Beneficio 10.jpg'
import { BannerInitial } from './BannerInitial'
import Icon1 from '../images/icono CARRO por dinero.png'
import Icon2 from '../images/icono PARTICULAR.png'
import Icon3 from '../images/icono POLIZAS.png'
import { RequisitosGenerales } from './RequisitosGenerales'
import { Beneficios } from './Beneficios'


export const Particular = () => {
    return (
        <>
            <BannerInitial
                img={Banner}
                titulo={"Particular"}                              
            />
            <div className='container mt-4'  >
                <div className='row '>
                    <div className='col text-center'>
                        <label className='fw-bold fs-5'> Solo elige el vehículo que mejor se adapte a tu estilo de vida</label>
                        <p>
                            Nosotros te ayudamos en la compra de ese vehículo de tus sueños.
                        </p>
                    </div>
                </div>
                <RequisitosGenerales
                    textPlazos="Hasta 60 meses"
                    textMontos="Desde COP 10.000.000"
                    textFami="Ingresos mínimos $1.500.000**"
                />
                 <div className='separator-container'>
                    <div className='separator separator-productivo'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Requisitos:</h2>
                        </div>
                    </div>
                </div>

                <div className='row '>
                    <div className='col-12 col-lg-6'>
                        <img src={Beneficio9} alt="beneficio 1" className='img-fluid rounded pt-4 '></img>
                    </div>
                    <div className='col-12 col-lg-6 align-self-center'>
                       
                        <ul className="list-group list-group-flush p-3">
                            <li>Crédito mínimo 10 millones hasta el 70% valor del Fasecolda.</li>                           
                            <li >Edad: de 21 a 69 años 11 meses.</li>
                            
                            
                            <li >Los porcentajes y plazos estarán sujetos a la capacidad de pago y endeudamiento del cliente.</li>
                            <li>Podrá sumar ingresos de familiares (hasta segundo grado de consanguinidad)</li>
                           
                            <li className="fst-italic">De acuerdo a perfil de evaluación (Empleados, profesional independiente, rentistas de capital, transportadores, pensionados)</li>
                        </ul>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito</a>
                        </div>
                    </div>
                </div>
               

                <div className='row'>
                    <div className='col'>
                        <div className="card card-noborder p-5">
                            <img src={Icon1} className="card-img mx-auto d-block icon-requisito" alt="..." />
                            <div className="text-center">
                                <p className="card-title fw-bold fs-5">Nuevos y Usados </p>

                            </div>
                        </div>


                    </div>
                    <div className='col'>

                        <div className="card card-noborder p-5">
                            <img src={Icon2} className="card-img mx-auto d-block icon-requisito" alt="..." />
                            <div className="text-center">

                                <p className="card-title fw-bold fs-5">Hasta 15 años de antigüedad</p>

                            </div>
                        </div>

                    </div>
                    <div className='col'>

                        <div className="card card-noborder p-5">
                            <img src={Icon3} className="card-img mx-auto d-block icon-requisito" alt="..." />
                            <div className="text-center">

                                <p className="card-title fw-bold fs-5">Particulares usados</p>

                            </div>
                        </div>

                    </div>

                </div>

                <div className='separator-container'>
                    <div className='separator separator-beneficio'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Beneficios:</h2>
                        </div>
                    </div>
                </div>
                <Beneficios
                    imgBeneficio1={Beneficio10}
                />
            </div>
        </>
    )
}
