import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "../Login";
import { Main } from "../home/Main";
import { NavBar } from "../NavBar";
import { Footer } from "../Footer";
import { Productivos } from "../creditLines/Productivos";
import { Polizas } from "../creditLines/Polizas";
import { LibreInversion } from "../creditLines/LibreInversion";
import { Particular } from "../creditLines/Particular";
import { PrivacyPolicies } from "../PrivacyPolicies";
import { Contact } from "../Contact";


export const AppRouter = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
      <Route path='/contactenos' element={<Contact />} />
      <Route path='/politicas' element={<PrivacyPolicies />} />
        <Route path='/polizas' element={<Polizas />} />
        <Route path='/libreinversion' element={<LibreInversion />} />
        <Route path='/particular' element={<Particular />} />
        <Route path='/productivos' element={<Productivos />} />
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Main />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}