import React from 'react'
import Img1 from '../images/7 BANNER FICARGO Libre Inversión B.jpg'
import Beneficio6 from '../images/foto Beneficio 6.jpg'
import Beneficio7 from '../images/foto Beneficio 7.jpg'
import Beneficio8 from '../images/foto Beneficio 8.jpg'
import { BannerInitial } from './BannerInitial'
import { RequisitosGenerales } from './RequisitosGenerales'

export const LibreInversion = () => {
    return (
        <>
            <BannerInitial
                img={Img1}
                titulo={"Libre inversión"}
            />
            <div className='container mt-4'  >
                <div className='row '>
                    <div className='col text-center'>
                    <label className='fw-bold fs-5'>Créditos sobre garantía real, DEJANDO EN GARANTÍA TU VEHÍCULO.</label>
                        <p>
                            
                            para el destino que necesites… vacaciones, remodela tu hogar, proyectos personales o lo que tu desees con amplios plazos de financiación

                        </p>
                    </div>
                </div>
                <RequisitosGenerales
                    textPlazos="Hasta 48 meses"
                    textMontos="Desde COP 10.000.000"
                    textFami="Ingresos mínimos $1.500.000"
                />
                 

                <div className='separator-container'>
                    <div className='separator separator-beneficio'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Beneficios:</h2>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-lg-6'>
                        <img src={Beneficio6} alt="beneficio 5" className='img-fluid rounded p-4'></img>
                    </div>
                    <div className='col-12 col-lg-6 align-self-center'>

                    <h5>Cambio de llantas</h5>
                        <p>Realice su crédito  para el cambio de llantas de su tractocamión o vehículo productivo</p>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>

                    <div className='col-12 col-lg-6 align-self-center order-last order-lg-first'>
                    <h5> Remplazo de vehículo</h5>
                        <p>Renueve su tractocamión o vehículo productivo antiguo y adquiera un crédito fácil y rápido para comprar uno totalmente nuevo </p>
                        <div className='offset-5 col-7 col-lg-5 pb-3'>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 order-first order-lg-last'>
                        <img src={Beneficio7} alt="beneficio 3" className='img-fluid rounded p-4'></img>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-lg-6'>
                        <img src={Beneficio8} alt="beneficio 4" className='img-fluid rounded p-4'></img>
                    </div>
                    <div className='col-12 col-lg-6 align-self-center'>

                        <h5> Reparaciones mecánicas</h5>
                        <p>Adquiera un crédito para todo tipo de reparaciones a su tractocamión o vehículo productivo</p>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
