import React from 'react'

export const Contact = () => {
    return (
        <>
            <div className='container' style={{ paddingTop: "76px" }}>

                <div className='separator-container'>
                    <div className='separator separator-contacto'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Contactenos</h2>
                        </div>
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-12 col-lg-6'>
                        <div className="card" >
                            <div class="card-header">
                                <label className="fw-bold fs-5">
                                    Principal Duitama (Boyacá)</label>
                            </div>
                            <div className="card-body">

                                <p>Carrera 42 No. 4 – 25 Estación de Servicio Tundama</p>
                                <div className='row'>
                                    <div className='col'> <p><label className='fw-bold'>Tel:</label> (+57) (608) 7600586</p></div>
                                    <div className='col'> <p><label className='fw-bold'>Cel:</label> (+57) 321 433 6643</p></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>

                        <div className="card" >
                            <div class="card-header">
                                <label className="fw-bold fs-5">Sucursal Bogotá D.C. </label>
                            </div>
                            <div className="card-body">

                                <p>Av. Ciudad de Cali (Kr 86) No. 10A 42 Interior 06 Bodega 03</p>
                                <p><label className='fw-bold'>Cel:</label>  (+57) 323 394 0516</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='map-area'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1403.3806064544221!2d-73.0375739408156!3d5.80475924706752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x8e6a4081ccf5be5b%3A0x259e07b6ed0dddc8!2sCra.%2042%20%234-25%2C%20Duitama%2C%20Boyac%C3%A1!3m2!1d5.8049235!2d-73.037398!5e0!3m2!1ses!2sco!4v1667575195372!5m2!1ses!2sco"
                                width="100%" height="100%"
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
