import React from 'react'
import Img1 from '../images/8 BANNER FICARGO Polizas B.jpg'
import Beneficio11 from '../images/foto Beneficio 11.jpg'
import { BannerInitial } from './BannerInitial'

import { RequisitosGenerales } from './RequisitosGenerales'
export const Polizas = () => {
    return (
        <>
            <BannerInitial
                img={Img1}
                titulo={"Pólizas"}
            />
            <div className='container mt-4'  >
                <div className='row '>
                    <div className='col text-center'>
                    <label className='fw-bold fs-5'>Financiamos el valor de tu póliza total anual</label>
                        <p>
                        Renovación durante tu crédito a través de alianzas comerciales con aseguradores de alta trayectoria y reconocimiento. 
                        </p>
                    </div>
                </div>
                <RequisitosGenerales
                    textPlazos="Hasta 12 meses"
                    textMontos="Desde COP 300.000"
                    textPoliza="De Vida y Todo Riesgo"
                />
                <div className='separator-container'>
                    <div className='separator separator-beneficio'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Beneficios:</h2>
                        </div>
                    </div>
                </div>
                <div className='row '>
                    <div className='col-12 col-lg-6'>
                        <img src={Beneficio11} alt="beneficio 11" className='img-fluid rounded pt-4 '></img>
                    </div>
                    <div className='col-12 col-lg-6 align-self-center'>
                       
                        <ul className="list-group list-group-flush p-3">
                            <li>Tranquilidad para ti y tu familia.</li>                           
                            <li >Respaldo de reconocidas aseguradoras.</li>
                            
                            
                            <li >Rápida aprobación.</li>
                            <li>Con el mejor acompañamiento de nuestos ejecutivos comerciales</li>
                           
                           
                        </ul>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
