import React from 'react'
import { Banner } from './Banner'
import Img1 from '../images/Rombo LINEA LIBRE INVERSION.png'
import Img2 from '../images/Rombo LINEA PARTICULAR.png'
import Img3 from '../images/Rombo LINEA POLIZAS.png'
import Img4 from '../images/Rombo LINEA PRODUCTIVOS.png'
import LogoPse from '../images/BotonPSE.png'
import LogoWs from '../images/Western-Union.png'
import QrBancolombia from '../images/QR_FICARGO.png'
import Tes1 from '../images/TESTIMONIO1.jpg'
import Tes2 from '../images/TESTIMINIO2.jpg'
import Tes3 from '../images/TESTIMINIO3.jpg'

export const Main = () => {
  return (

    <>
      <Banner />
      <div className='container'>

        <div className='row mt-2'>
          <div className='col-12 col-md-3 p-4 text-center credit-diamond'>
            <a href='/productivos'>
              <img className='img-fluid' src={Img4} alt="productivos" />
              <label className=" fw-bold fs-5">Productivos</label>
              <p>Tractocamión o de carga</p>
            </a>
          </div>
          <div className='col-12 col-md-3 p-4 text-center credit-diamond'>
            <a href='/particular'>
              <img className='img-fluid' src={Img2} alt="particular" />
              <label className=" fw-bold fs-5">Particular</label>
              <p>El vehículo de tus sueños</p>
            </a>
          </div>
          <div className='col-12 col-md-3 p-4 text-center credit-diamond'>
            <a href='/libreinversion'>
              <img className='img-fluid' src={Img1} alt="libreinversion" />
              <label className=" fw-bold fs-5">
                Libre Inversión
              </label>
              <p> Para el destino que necesites</p>
            </a>
          </div>
          <div className='col-12 col-md-3 p-4 text-center credit-diamond'>
            <a href='/polizas'>
              <img className='img-fluid' src={Img3} alt="polizas" />
              <label className=" fw-bold fs-5">Pólizas</label>
              <p>Con alianzas comerciales</p>
            </a>
          </div>

        </div>
        

        <div className='separator-container-main '>
          <div className='separator separator-index'>
            <div className='blur-separator blur-main '>
              <h2 className='pt-4  text-center'>Lo que dicen nuestros clientes:</h2>
            </div>
          </div>
        </div>


        <div className='row mt'>
          <div className='col-12 col-lg-4'>
            <div className="card card-noborder p-5 ">
              <img src={Tes2} className="card-img mx-auto d-block rounded-circle" alt="..."  />
              <div className="text-center">
                <h5>Hamer Cañón</h5>
                <p>Gracias a Ficargo pude cumplir mi sueño de comprar mi primer vehículo para trabajar, conseguir
                  un patrimonio y cumplir los proyectos con mi familia.
                </p>

              </div>
            </div>
          </div>
          <div className='col-12 col-lg-4'>
            <div className="card card-noborder p-5">
              <img src={Tes1} className="card-img  mx-auto d-block rounded-circle" alt="..." />
              <div className="text-center">
                <h5>Dunia Montañez</h5>
                <p>
                  Gracias a este préstamo fue posible cumplir nuestro sueño como familia de comprar camioneta, con un proceso sencillo y rápido.
                </p>

              </div>
            </div>


          </div>
          <div className='col-12 col-lg-4'>
            <div className="card card-noborder p-5">
              <img src={Tes3} className="card-img  mx-auto d-block rounded-circle" alt="..."  />
              <div className="text-center">
                <h5>Francisco Cuellar  </h5>
                <p>
                Renové mi vehículo antiguo por un cero kilómetros gracias a la asesoría del ejecutivo de crédito Ficargo. En el proceso obtuve un rápido desembolso, así como la ventaja de tener cuotas fijas.
                </p>

              </div>
            </div>


          </div>


        </div>
        <div className='row'>
          <div className='col-12'>
            <h3>Realice sus pagos en:</h3>
          </div>
          <div className='col-lg-3 col-12 credit-diamond'>
            <a target="_blank" rel='noreferrer' href="https://sadminweb.sadmin.net/PayValida.aspx?id9MC/7TNSnbSiinPZFW9Q==">
            <img className='icon-requisito mx-auto d-block' src={LogoWs} alt="logoWs" />
            </a>
          </div>
          <div className='col-lg-3 col-12 credit-diamond'>
          <a target="_blank" rel='noreferrer' href="https://sadminweb.sadmin.net/PayValida.aspx?id9MC/7TNSnbSiinPZFW9Q==">
            <img className='icon-requisito mx-auto d-block' src={LogoPse} alt="logoPse" /></a>
          </div>
          <div className='col-lg-4 col-12 credit-diamond'>
            <img className='icon-requisito mx-auto d-block' src={QrBancolombia} alt="QrBancolombia" />
            </div>
          

        </div>
      </div>



    </>
  )
}
