import React from 'react'
import Icon1 from '../images/icono MONTOS.png'
import Icon2 from '../images/icono PLAZOS.png'
import Icon3 from '../images/icono INGRESOS FAMILIARES.png'
import Icon4 from '../images/icono POLIZAS.png'

export const RequisitosGenerales = ({textMontos,textPlazos,textFami="", textPoliza=""}) => {
  return (
    <>
    <div className='row'>
                    <div className='col-12 col-md-4'>
                        <div className="card card-noborder p-3">
                            <img src={Icon1} className="card-img mx-auto d-block icon-requisito" alt="..."   />
                            <div className="text-center">
                            <label className='fw-bold fs-5'>Montos</label>
                                <p className="card-title">{textMontos}</p>

                            </div>
                        </div>


                    </div>
                    <div className='col-12 col-md-4'>

                        <div className="card card-noborder p-3">
                            <img src={Icon2} className="card-img mx-auto d-block icon-requisito" alt="..."  />
                            <div className="text-center">
                                <label className='fw-bold fs-5'>Plazos</label>
                                <p className="card-title">{textPlazos}</p>

                            </div>
                        </div>

                    </div>
                    {
                        textFami!==""?<div className='col-12 col-md-4'>

                        <div className="card card-noborder p-3">
                            <img src={Icon3} className="card-img mx-auto d-block icon-requisito" alt="..."  />
                            <div className="text-center">
                            <label className='fw-bold fs-5'>Se admiten ingresos familiares</label>
                                <p className="card-title">{textFami}</p>

                            </div>
                        </div>

                        </div>
                        :
                        ''
                    }
                    {
                        textPoliza!==""?<div className='col-12 col-md-4'>

                        <div className="card card-noborder p-3">
                            <img src={Icon4} className="card-img mx-auto d-block icon-requisito" alt="..."  />
                            <div className="text-center">
                            <label className='fw-bold fs-5'>Polizas</label>
                                <p className="card-title">{textPoliza}</p>

                            </div>
                        </div>

                        </div>
                        :
                        ''
                    }
                    
                </div>
                </>
  )
}
