import React from 'react'
import Logo from './images/Logo FICARGO para Banner.png';
import LogoWsp from './images/LogoWhatsApp.png';


export const Footer = () => {
    return (
        <div className="container mt-5" style={{ borderTop: "1px solid #d8e7f1", backgroundColor: "white" }}>
            <div className="btn-whatsapp" >
            
            <a href="https://web.whatsapp.com/send?phone=+573108544382" rel="noreferrer" target="_blank">
            <img src={LogoWsp} alt="..." />
        </a></div>
            <footer className="pt-5 ">
                <div className="row">
                    <div className="col-6 col-lg-2 ">

                        <ul className="nav flex-column">
                          
                            <li className="nav-item mb-2"><a href="https://sadminweb.sadmin.net/PayValida.aspx?id9MC/7TNSnbSiinPZFW9Q==" className="nav-link p-0 text-muted">Pague aquí</a></li>
                            <li className="nav-item mb-2"><a href="/politicas" className="nav-link p-0 text-muted"> Proteccion de datos</a></li>
                            <li className="nav-item mb-2"><a href="/politicas" className="nav-link p-0 text-muted">Politicas de seguridad</a></li>
                            <li className="nav-item mb-2"><a href="/contactenos" className="nav-link p-0 text-muted">Contáctenos</a></li>
                        </ul>
                    </div>



                    <div className="col-6 col-lg-3 ">
                        <img src={Logo} className="img-fluid" alt="logo" style={{ maxHeight: "50px" }} />
                    </div>
                    <div className="col-6 col-lg-3 mb-3">
                        <h5>Principal Duitama (Boyacá)</h5>
                        <ul className="nav flex-column">

                            <li className="nav-item mb-2">Carrera 42 No. 4 – 25 Estación de Servicio Tundama</li>
                            <li className="nav-item mb-2">Tel: (+57) 8 7600586</li>
                            <li className="nav-item mb-2">Cel: (+57) 321 433 6643</li>

                        </ul>
                    </div>
                    <div className="col-6 col-lg-3 mb-3">
                        <h5>Sucursal Bogotá D.C.</h5>
                        <ul className="nav flex-column">

                            <li className="nav-item mb-2">Av. Ciudad de Cali (Kr 86) No. 10A 42 Interior 06 Bodega 03</li>
                            <li className="nav-item mb-2">Cel: (+57) 323 394 0516</li>
                            <li className="nav-item mb-2">Cel: (+57) 310 854 4382</li>
                            
                        </ul>
                    </div>


                </div>


            </footer>
        </div>
    )
}
