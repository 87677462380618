import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import ImgLogo from '../images/Logo FICARGO para Banner.png'
import ConstanstUI from '../utilities/ConstanstUI'

export const BannerInitial = ({ img, titulo}) => {
    const [index, setIndex] = useState(0);
    const {LINK_LIBRE_INVERSION,LINK_PARTICULAR,LINK_PRODUCTIVO,LINK_POLIZAS}=ConstanstUI
    const [url,setUrl] = useState("/")

    useEffect(() => {
      if(titulo==="Productivos"){
        setUrl(LINK_PRODUCTIVO)
      }
      if(titulo==="Particular"){
        setUrl(LINK_PARTICULAR)
      }
      if(titulo==="Libre inversión"){
        setUrl(LINK_LIBRE_INVERSION)
      }
      if(titulo==="Pólizas"){
        setUrl(LINK_POLIZAS)
      }
    }, [titulo])
    

    

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <>
            <Carousel className='no-arrows' style={{ paddingTop: "76px" }} activeIndex={index} onSelect={handleSelect} variant="dark">
                <Carousel.Item className='active' >
                    <img
                        className="d-block w-100"
                        src={img}
                        alt="Banner inicial"
                    />
                    <Carousel.Caption className='carousel-productivo-b'>
                    <div className='row '>
                            <div className='col-12'>

                            <img src={ImgLogo} alt="logobanner" className='img-banner'></img>
                            </div>
                        </div>
                       
                        <div className='row '>
                            <div className='col-12'>

                                <h3> {titulo}</h3>
                            </div>
                        </div>
                        <div className='row my-4   '>


                            <div className='offset-md-6 col-5 '>
                                <a className='btn btn-outline-primary btn-sm  d-none d-lg-block' 
                                target="_blank" 
                                rel="noreferrer"
                                 href={url}>Solicitar Crédito</a>
                            </div>
                        </div>


                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

        </>
    )
}
