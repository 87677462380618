
import Banner from '../images/5 BANNER  Productivos B.jpg'
import Beneficio1 from '../images/foto Beneficio 1.jpg'
import Beneficio2 from '../images/foto Beneficio 2.jpg'
import Beneficio3 from '../images/foto Beneficio 3.jpg'
import Beneficio4 from '../images/foto Beneficio 4.jpg'
import Beneficio5 from '../images/foto Beneficio 5.jpg'
import Icon1 from '../images/icono VOLQUETAS.png'
import Icon2 from '../images/icono CAMIONES.png'
import Icon3 from '../images/icono MONTOS.png'
import Icon4 from '../images/icono TAXI.png'
import { BannerInitial } from './BannerInitial'
import { RequisitosGenerales } from './RequisitosGenerales'


export const Productivos = () => {
    
    return (
        <>
            <BannerInitial
                img={Banner}
                titulo={"Productivos"}
            />
            <div className='container mt-2'  >
                <div className='row '>
                    <div className='col text-center'>
                        <label className='fw-bold fs-5'> Te ayudamos a cumplir tu sueño de ser propietario de tu tractocamión o vehículo de carga.</label>
                        <p>
                            Adquiere tu crédito con nosotros para la compra de vehículos de carga pesada, volqueta, automóvil o bus de pasajeros con plazos hasta de 72 meses.
                        </p>
                    </div>
                </div>
                <RequisitosGenerales
                    textPlazos="hasta 72 meses"
                    textMontos="Hasta COP 900.000.000"
                    textFami="Podrá sumar ingresos familiares o de deudor solidario"
                />
                <div className='separator-container'>
                    <div className='separator separator-productivo'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Requisitos:</h2>
                        </div>
                    </div>
                </div>


                <div className='row '>
                    <div className='col-12 col-lg-6 '>
                        <img src={Beneficio1} alt="beneficio 1" className='img-fluid rounded p-4'></img>
                    </div>
                    <div className='col-12 col-lg-6 pt-2'>

                        <h3 > Carga pesada</h3>
                        <label>Este crédito es perfecto para comprar un tractocamión, volqueta u otro vehiculo productivo para impulsar tu negocio.</label>
                        <ul className="list-group list-group-flush p-3">
                            <li>Edad desde 26 años hasta 65 años.</li>
                            <li >Los porcentajes y plazos estarán sujetos al estudio de crédito, la capacidad de pago y endeudamiento del cliente.</li>
                            <li >Carga pesada: vehículos superiores a 10,5 Toneladas</li>
                            <li >Podrá sumar ingresos familiares o de deudor solidario</li>
                            <li >Modelos a financiar hasta 17 años de antigüedad.</li>
                           
                        </ul>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>

                    <div className='col-12 col-lg-6 order-last order-lg-first'>
                        <h3>Taxis</h3>
                        <label>Renueva tu flota con nosotros.</label>
                        <ul className="list-group list-group-flush p-3">
                            <li>Edad desde 24 años hasta 65 años.</li>
                            <li >Propietario de vehículo taxi superior a un año e ingresos certificados por empresa.</li>
                            <li >Conductor con solidario propietario de taxi superior a un año.</li>
                           
                            <li >Taxis modelo 2006 a 2011, financiación del 60% del valor cupo más valor fasecolda plazo a 48 meses.</li>
                            <li>Taxis modelo 2012 a 2022, financiación del 70% del valor cupo más valor fasecolda plazo a 72 meses.</li>
                        </ul>
                        <div className=' col-12 col-lg-5 pb-3 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 order-first order-lg-last'>
                        <img src={Beneficio2} alt="beneficio 1" className='img-fluid rounded p-4'></img>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-lg-6 '>
                        <img src={Beneficio3} alt="beneficio 1" className='img-fluid rounded p-4'></img>
                    </div>
                    <div className='col-12 col-lg-6 pt-2'>

                        <h3 > Transporte pasajeros</h3>
                        <label>Financia con nosotros transporte de pasajeros intermunicipal y servicio especial.</label>
                        <ul className="list-group list-group-flush p-3">
                            <li>Edad desde 26 años hasta 65 años.</li>                            
                            <li >Podrá sumar ingresos familiares o de deudor solidario</li>
                            <li >Modelos a financiar hasta 8 años de antigüedad.</li>
                           
                        </ul>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                </div>

                <div className='row'>
                     <div className='col-12 col-lg-4'>

                        <div className="card card-noborder p-3">
                            <img src={Icon2} className="card-img mx-auto d-block icon-requisito" alt="..." />
                            <div className="text-center">
                            <label className='fw-bold fs-5'>Financiación</label>
                                <p className="card-title"> Hasta el 70% sobre garantía</p>

                            </div>
                        </div>

                    </div>
                     <div className='col-12 col-lg-4'>

                        <div className="card card-noborder p-3">
                            <img src={Icon4} className="card-img mx-auto d-block icon-requisito" alt="..." />
                            <div className="text-center">
                                <label className='fw-bold fs-5'>Nuevos</label>
                                <p className="card-title">Hasta 72 meses</p>

                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-4'>
                        <div className="card card-noborder p-3">
                            <img src={Icon1} className="card-img mx-auto d-block icon-requisito" alt="..." />
                            <div className="text-center">
                                 <label className='fw-bold fs-5'>Usados</label>
                                <p className="card-title">hasta 60 meses</p>

                            </div>
                        </div>


                    </div>
                   
                   
                   
                    
                </div>
                <div className='separator-container'>
                    <div className='separator separator-beneficio'>
                        <div className='blur-separator '>
                            <h2 className='pt-4  text-center'>Beneficios:</h2>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-lg-6'>
                        <img src={Beneficio5} alt="beneficio 5" className='img-fluid rounded p-4'></img>
                    </div>
                    <div className='col-12 col-lg-6 align-self-center'>

                        <h5> Tractocamiones productivos</h5>
                        <p>Este crédito  es perfecto para comprar un tractocamión, volqueta u otro vehiculo productivo para impulsar tu negocio</p>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>

                    <div className='col-12 col-lg-6 align-self-center order-last order-lg-first'>
                        <h5>Buses y colectivos</h5>
                        <p>Financia todo tipo de transporte publico como buses intermunicipales, municipales o colectivos</p>
                        <div className=' col-12 col-lg-5 pb-3'>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito </a>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 order-first order-lg-last'>
                        <img src={Beneficio3} alt="beneficio 3" className='img-fluid rounded p-4'></img>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-lg-6'>
                        <img src={Beneficio4} alt="beneficio 4" className='img-fluid rounded p-4'></img>
                    </div>
                    <div className='col-12 col-lg-6 align-self-center'>

                        <h5> Maquinaria amarilla</h5>
                        <p>Con este Crédito  puede adquirir equipo de carga pesada para sus proyectos </p>
                        <p className='fst-italic'>NOTA: Financiación sugeta a marca y modelo.</p>
                        <div className='offset-5 offset-lg-7 col-7 col-lg-5 '>
                            <a className='btn btn-primary' href='/'>Solicitar Crédito</a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
